<template>
  <div class="">
    <nav class="navbar sticky-top bg-white" style="min-height: 10vh">
      <div class="container-fluid" style="display: flex; flex-direction: row; justify-content: space-between">
        <div class="">
          <a class="navbar-brand" style="display: flex; flex-direction: row">
            <img v-if="!listVacantes.vacante.private && listVacantes.user.photo && listVacantes.user.photo.link" 
                :src="listVacantes.user.photo.link" 
                alt="Logo" width="80" class="d-inline-block align-text-center">
            <img v-else :src="'/images/logo2.png'" 
                alt="Logo" width="80" class="d-inline-block align-text-center" />
          </a>
        </div>
        <div class="">
          <ul class="navbar-nav">
            <!-- <li class="nav-item">
                                <router-link class="nav-item text-decoration-none" to="/login">
                                    <a class="nav-link" style="color: #517aa8; font-size: 18px;">Iniciar Sesión</a>
                                </router-link>
                            </li> -->
            <li class="nav-item">
              <router-link class="nav-item text-decoration-none" to="/login">
                <span class="nav-link" style="color: #517aa8; font-size: 18px; font-weight: bold">Iniciar Sesión</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="content">
      <div class="body-view">
        <div class="vac-A-container">
          <div class="vacantes-a">
            <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 15px">
              <span class="vacante-header-title-a" v-if="listVacantes.vacante.private">
                EMPRESA CONFIDENCIAL
              </span>
              <span class="vacante-header-title-a" v-else>
                {{ listVacantes.company.nameCompany }}
              </span>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 15px">
              <span class="vacante-header-title-a">
                {{ listVacantes.vacante.titulo }}
              </span>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="second-line-header">
                  <div class="txt-header">
                    <box-icon name="location-plus"></box-icon>
                    <span class="txt-h"> {{ listVacantes.vacante.ubicacion }}, {{ listVacantes.vacante.estado }}.</span>
                  </div>
                  <div class="txt-header" v-if="!listVacantes.vacante.privateSalary">
                    <box-icon name="money"></box-icon>
                    <span class="txt-h"> De {{ listVacantes.vacante.becaDe }} a {{ listVacantes.vacante.becaA }} Mensual. </span>
                  </div>
                  <div class="txt-header" v-if="listVacantes.vacante.privateSalary">
                    <div class="txt-header" v-show="listVacantes.vacante.type === 'Becario'">
                      <box-icon name="money"></box-icon>
                      <span class="txt-h"> Beca Competitiva</span>
                    </div>
                    <div class="txt-header" v-show="listVacantes.vacante.type === 'Egresado'">
                      <box-icon name="money"></box-icon>
                      <span class="txt-h"> Salario Competitivo</span>
                    </div>
                  </div>
                  <div class="txt-header">
                    <box-icon name="graduation" type="solid"></box-icon>
                    <span class="txt-h">Tipo: {{ listVacantes.vacante.type }}</span>
                  </div>
                  <div class="txt-header">
                    <box-icon name="city" type="solid"></box-icon>
                    <span class="txt-h"> Modalidad: {{ listVacantes.vacante.modalidad }} </span>
                  </div>
                  <div class="txt-header" style="text-transform: capitalize" v-show="listVacantes.vacante.contratacion">
                    <box-icon name="user-check"></box-icon>
                    <span class="txt-h"> Contratación: {{ listVacantes.vacante.contratacion }}</span>
                  </div>
                  <div class="txt-header" style="text-transform: capitalize">
                    <box-icon name="briefcase"></box-icon>
                    <span class="txt-h"> Horario: {{ listVacantes.vacante.workDayInitial }} - {{ listVacantes.vacante.workDayFinal }} </span>
                    <span v-show="listVacantes.vacante.horarioDe && listVacantes.vacante.horarioA"> {{ listVacantes.vacante.horarioDe }} - {{ listVacantes.vacante.horarioA }} hrs </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="second-line-header">
                  <div class="txt-header">
                    <box-icon name="file"></box-icon>
                    <span class="txt-h"
                      >Nivel Escolar Requerido:
                      {{ listVacantes.vacante.educacionMinima }}
                    </span>
                  </div>
                  <div class="txt-header">
                    <box-icon name="world"></box-icon>
                    <span class="txt-h">Idioma Requerido: {{ listVacantes.vacante.idiomaMinimo }}</span>
                  </div>
                  <div class="txt-header">
                    <box-icon name="line-chart"></box-icon>
                    <span class="txt-h">Nivel de Idioma Requerido: {{ listVacantes.vacante.nivelMinimo }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="listVacantes-information-2 mt-2">
            <div class="subtitle-vac-a">
              <span class="titulo-informacion">INFORMACIÓN DE LA VACANTE</span>
            </div>
            <div class="vac-description" style="width: 100%">
              <div v-html="listVacantes.vacante.descripcion" />
            </div>
            <div class="subtitle-vac-a">
              <span class="titulo-informacion">HABILIDADES, CERTIFICACIONES Y/O SISTEMAS REQUERIDOS</span>
            </div>
            <div class="vac-description" style="width: 100%">
              <span v-html="listVacantes.vacante.habilidades"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import moment from "moment"
import { defineComponent } from "vue";
import { UserService } from "@/services";


export default defineComponent({
    data: () => ({
        moment: moment,
        search: '',
        listVacantes: [],
        id: "",
    }),
    created() {
        this.id = this.$route.params.id;
        this.getCompany(this.id);
    },
    methods: {
        async getCompany(busqueda) {
            const resp = await UserService.vacantesVista(busqueda);
            const { data } = resp.data;
            this.listVacantes = data;
            console.log("datos", this.listVacantes)
            let { vacanteSuspended, status } = this.listVacantes;
            if (vacanteSuspended == true && status == false) {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: "Vacante No Encontrada",
                    showConfirmButton: false,
                    timer: 6000,
                });
            }

        },
    }
});
</script>
<style land="scss" scoped>
@import "../styles/vistaVacantes.scss";
</style>
